var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageTitle',{attrs:{"title":"检查报告","fixed":"","backTo":"/home"}}),_c('List',{staticStyle:{"margin-top":"1rem"}},[_c('div',{staticStyle:{"text-align":"center","width":"100%"}},[_c('van-button',{staticStyle:{"width":"140px"},attrs:{"type":"primary","name":"buttonXF"},on:{"click":_vm.camerastart}},[_vm._v("扫描条形码")]),_c('van-button',{staticStyle:{"margin-left":"20px","width":"140px"},attrs:{"type":"primary","name":"buttonCZ"},on:{"click":_vm.SRTXM}},[_vm._v(" 手动输入条形码 ")])],1),_c('van-dialog',{staticStyle:{"color":"#3f9775","border-radius":"8px"},attrs:{"show-cancel-button":"","confirm-button-text":"取消","cancel-button-text":"确定"},on:{"cancel":_vm.CS,"confirm":_vm.GB},model:{value:(_vm.tcs),callback:function ($$v) {_vm.tcs=$$v},expression:"tcs"}},[_c('p',{staticStyle:{"text-align":"center","margin-top":"10px"}},[_vm._v("手动输入条形码")]),_c('input',{staticStyle:{"background":"#ffffff","width":"260px","height":"30px","border":"1.21px solid #3f9775","box-shadow":"inset 0px 0px 5px 0px rgba(119, 119, 119, 1)","font-size":"12px","margin-left":"20px","padding-left":"2px"},attrs:{"oninput":"this.value=this.value.replace(/\\D/g,'')","type":"number","pattern":"[0-9]*","id":"TSK"}}),_c('div',{staticStyle:{"height":"10px"}})])],1),_c('div',{staticStyle:{"position":"relative","width":"85%","margin":"15px auto auto auto"}},[_c('List',{attrs:{"width":100}},[_vm._l((_vm.items),function(item,index){return _c('ListItem',{key:index,attrs:{"to":_vm.ba(
            item.exam_order_name,
            item.brxm,
            item.exam_time.replace('T', ' '),
            item.exam_rpt_flow,
            item.judge,
            item.sampleno
          )},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"height":"5px"}}),_c('div',{staticClass:"borderColor"},[_c('div',{staticClass:"leftArea"},[_c('p',{staticClass:"fontmd"},[_vm._v("检查项目："+_vm._s(item.exam_order_name))]),_c('p',{staticClass:"fontsm"},[_vm._v("姓名："+_vm._s(item.brxm))]),_c('p',{staticClass:"fontsm"},[_vm._v(" 检查时间："+_vm._s(item.exam_time.replace("T", " ") ? item.exam_time.replace("T", " ") : "")+" ")])])])]},proxy:true}],null,true)})}),_c('div',{staticClass:"bottomAdd",staticStyle:{"text-align":"center"}},[_c('p',[_vm._v("注:目前仅提供两年内检验、超声、放射的报告查询")])])],2)],1),(_vm.showLoading)?_c('van-loading',{staticClass:"loading",attrs:{"size":"24px","vertical":""}},[_vm._v("加载中...")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }